
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.Main_footer_bg{
    padding: 20px 10px ;
    background-color: #000000d6;
    border-top: 1px solid white;
}

.cpr {
    color: #ffffff;
    margin-top: 1rem;
    /* font-family: 'Poppins', sans-serif; */

}

.join_c{
    text-transform:capitalize;
    color: #ffffff;
    font-size:1.3rem;
    text-decoration: underline;
    font-weight: 600;
    /* font-family: 'Poppins', sans-serif; */


}

.icons_clr {
    font-size:1.5rem  !important;
    color:white !important;
}


.around_social {
    background-color: #212835;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.alli {
    color: #ffffff;
    /* font-family: 'Poppins', sans-serif; */
    /* text-transform: capitalize; */

}
.social_icons a img{
    width: 15%;
}