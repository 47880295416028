

.main_landing {
    padding: 40px 10px;
    /* background-image: url('../Assets/bg5.png'); */
    background-position:center;
    background-repeat: no-repeat;
    background-size: 50%;
    /* font-family: 'Inter', sans-serif; */
}
.main_div_landing{
    margin-top: 5rem;
    font-family: 'Fugaz One', sans-serif !important;
}
.main_heading_landing {
    color: white;
    font-size: 3rem;
    text-align: center;
    /* font-family: 'Inter', sans-serif; */
    font-weight: 500;
}

::placeholder {
    color: white;
}

.landing_box {
    font-family: 'Fugaz One', sans-serif !important;
    /* From https://css.glass */
    /* background: #1D2125; */
    /* background-color: #2453b663; */
    /* background-color: #ffffff38; */
    background: rgb(138, 245, 131);
    /* background: radial-gradient(276.49% 100% at 50.11% 100.00%, rgba(17, 153, 250, 0.40) 0%, rgba(17, 153, 250, 0.00) 100%),linear-gradient(0deg, rgba(1, 1, 24, 0.40) 0%, rgba(1, 1, 24, 0.40) 100%),#002F61; */
    /* opacity: 0.9; */
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.18); */
    padding: 20px;
    position: relative;
    z-index: 3 !important;
}

.bg_landing {
    position: absolute;
    background-color: #002D63;
    top: 0px;
    width: 400px;
    height: 300px;
    border-radius: 40%;
    z-index: -1;
    filter: blur(5px);
}
.copied{
    color: black;
}
.copied:hover{
    color: black;

}
.this_ha {
    z-index: 10 !important;
}

.info_pre h6 {
    color: white;
    font-size: 16px;
    font-family: 'Fugaz One', sans-serif;
}

.info_pre h2 {
    font-size: 1.5rem;
    color: white;
    font-family: 'Fugaz One', sans-serif;
}

.buy_br {
    background-color: #14202E;
    border-radius: 10px;
    padding: 10px;
    font-family: 'Fugaz One', sans-serif !important;
}

.buy_br h5 {
    color: white;
    margin-bottom: 0px;
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Fugaz One', sans-serif !important;
}

.rate h4 {
    color: white;
    font-size: 1.6rem;
    font-family: 'Fugaz One', sans-serif !important;
}

.buy_chain_box {
    /* background-color: black; */
    background: rgb(138, 245, 131);
    /* background: radial-gradient(276.49% 100% at 50.11% 100.00%, rgba(17, 153, 250, 0.40) 0%, rgba(17, 153, 250, 0.00) 100%),linear-gradient(0deg, rgba(1, 1, 24, 0.40) 0%, rgba(1, 1, 24, 0.40) 100%),#002F61; */
    padding: 10px;
    border-radius: 10px;
    /* height: 58vh; */
    font-family: 'Fugaz One', sans-serif !important;
}



@media only screen and (max-width:600px) {
    .main_heading_landing {
        font-size: 28px !important;
        font-family: 'Fugaz One', sans-serif !important;
    }

    .info_pre h2 {
        font-size: 18px;
        font-family: 'Fugaz One', sans-serif !important;

    }

    .buy_br h5 {
        font-size: 20px;
        font-family: 'Fugaz One', sans-serif !important;
    }
}